import styled from 'styled-components';
import { Link } from 'gatsby';
import { Colors } from './../../../aquaplot/colors';

export const StyledLink = styled(Link)`
    & {
        text-align: center;
        padding: 0.5em;
        margin: 0.5em;
        flex: 0 1 35%;
        max-width: 200px;
        border: 1px solid ${Colors.aqp_white};
        background-color: ${Colors.aqp_deep_sea};
        color: ${Colors.aqp_white};
        h3{
            color: ${Colors.aqp_white};
            font-size: small;
            margin: 10px 0px 0px 0px;
            border-bottom: none;
        }
        h3::before{
            border-bottom: none!important;
        }
    }

    &:hover{
        color: ${Colors.aqp_sundown};
        h3{
            color: ${Colors.aqp_sundown};
        }
    }
`