import React, { Component } from "react"

import Layout from './../components/Layout';

export default class MDXTemplate extends Component {
    render() {
        const { children, pageContext } = this.props
        const { title } = pageContext;
        
        return (
            <Layout defaultHeader={title}>
                <div
                    id="doc-content"
                    className="underlined-h3"
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    { children }
                </div>
            </Layout>
        )
    }
  }
