import styled from 'styled-components';
import { Link } from 'gatsby';
import Theme, { Colors } from './../../../aquaplot/colors';

export const ProductCard = styled.div`
    padding: 4px;
    flex: 1 1 auto;
    margin: 15px;

    background-color: ${Colors.aqp_white};
    box-shadow: 0 0 20px 0 rgba(${Theme.primaryColor.rgb}, 0.2);

    p{
        padding: 10px 10px;
        text-align:inherit;
    }

    ul { 
        border-top: 1px solid ${Colors.aqp_deep_sea};
        list-style: none;
        margin: 0 !important;  
        padding: 10px 10px;

        li {
            text-align:inherit;
            padding: 5px 5px;
        }
    }

    ul li:nth-child(2n+2) {
        background: rgba(${Theme.primaryColor.rgb}, 0.05);
    }
`

export const DocumentationLink = styled(Link)`
    && {
        padding: .5em;
        color: ${Colors.aqp_coastal_water};
        z-index: 99;
    }
`

export const TitleDiv = styled.div`
    text-align: center;

    h3 {
        ::after{
            content:"\\A ~ \\A";
            white-space: pre;
        }
    }
`