import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/hgr/ws/website-js/src/templates/infoDocTemplateMdx.js"
import ApiProduct from './../components/Api/Product'
import ProductCard from './../components/Api/ProductCard';
import { ProductCardContainer } from './../components/Api/styledComponents';
import { 
    Request as RoutingRequest,
    Response as RoutingResponse
} from './../data/RoutingGeojson';
import { 
    Request as AisRequest,
    Response as AisResponse
} from './../data/AisSample';
import { geojsonIoLink } from './../utils/geojson.io';
import Octicon, {
  Database, 
  Globe, 
  Milestone, 
  Search, 
  Eye
} from '@githubprimer/octicons-react'
export const frontmatter = {
  path: "/api",
  date: "2018-10-06",
  title: "API Services",
}
export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>



<ProductCardContainer>
  <ProductCard 
    anchor="#routing-api"
    icon={<Octicon size="large" icon={Milestone}/>}
    name="Routing API"
  />
  <ProductCard 
    anchor="#ais-api"
    icon={<Octicon size="large" icon={Globe}/>}
    name="AIS API"
  />
  <ProductCard 
    anchor="#placeholder-notice"
    icon={<Octicon size="large" icon={Search}/>}
    name="Geocode API"
  />
  <ProductCard 
    anchor="#placeholder-notice"
    icon={<Octicon size="large" icon={Eye}/>}
    name="Weather API"
  />
</ProductCardContainer>
<ApiProduct 
    id="routing-api"
    title="Sea Routing API"
    response={RoutingResponse}
    request={RoutingRequest}
    mapViewUrl={geojsonIoLink(RoutingResponse)}
    apiDocsUrl="/api/docs#routing"
    descriptionText={
        <>
        <div style={{padding: 10}}>
            Get information for a particular route including S(ECA) distance 
            and waypoints for plotting the voyage on a map.
            Highly customizable by allowing or disabling crossing predefined 
            passages and channels.
        </div>
        <div style={{padding: 10}}>
            Allow or prohibit channels and passages:
            <ul>
                <li>Panama</li>
                <li>Suez</li>
                <li>Kiel</li>
                <li>Northeast Passage</li>
                <li>Northwest Passage</li>
            </ul>
        </div>
        <div style={{padding: 10}}>
            Tell our algorithm to minimize areas of interest:
            <ul>
                <li>ECA Areas</li>
                <li>HRA Areas</li>
                <li>JWC Areas</li>
            </ul>
        </div>
        <div style={{padding: 20}}>
            You can even set your own custom areas with each request
            and our algorithm will avoid them when finding a route.
            Use this feature for example to prevent certain passages
            on a laden leg vs. a ballast leg.
        </div>
        </>
    }
/>
<ApiProduct 
    id={"ais-api"}
    title="AIS API"
    response={AisResponse}
    request={AisRequest}
    mapViewUrl={""}
    apiDocsUrl="/api/docs#ais"
    descriptionText={
      <>
      <div style={{padding: 10}}>
        Get the latest AIS data for any vessel from our combined
        (terrestrial and satellite) AIS data feed.
        You can track any vessel with exactly the frequency that you need.
      </div>
      <div style={{padding: 10}}>
        We have the right pricing models for your needs, whether that's
        tracking vessels only sporadically or continuously.
        Get in touch to learn more!
      </div>
      </>
    }
/>
<aside id="placeholder-notice" className="notice">
  We are currently redesigning large sections of this website.
  Check back later for more information about our other API services including weather forecast data, locations database and more.
</aside>

<MDXTag name="h2" components={components}>{`How To Get Started - Next Steps`}</MDXTag>
<MDXTag name="p" components={components}>{`Sign  up for a free trial account for Aquaplot Explorer and then
message our support team via our `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/contact"}}>{`online form`}</MDXTag>{` to receive API access.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Pricing`}</MDXTag>
<aside className="notice">
  We are currently redesigning large sections of this website.
  Check back later for up-to-date pricing information or <a href="/contact">get in touch with us</a>.
  We have the right pricing model for your business needs.
</aside>
</MDXTag>

export const _frontmatter = {};

  