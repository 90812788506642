import React from 'react';
import ReactJson from './../../JsonView';
import {
    ProductCard,
    DocumentationLink,
    TitleDiv
} from './styledComponents';


const ApiProduct = ({
    id,
    title,
    response,
    request,
    descriptionText,
    apiDocsUrl,
    mapViewUrl
}) => {
    return (
        <div style={{marginBottom: 40}} id={id}>
            <ProductCard>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <div style={{flex: '1 1 400px'}}>
                        <TitleDiv>
                            <h3>{title}</h3>
                        </TitleDiv>
                        {descriptionText}
                        <h3 style={{paddingLeft: 10}}>Resources</h3>
                        <DocumentationLink to={apiDocsUrl}>API Documentation</DocumentationLink>
                    </div>
                    <div style={{flex: '2 1 400px'}}>
                        <h4>Request</h4>
                        <ReactJson 
                            src={request} 
                            theme="shapeshifter"
                            displayDataTypes={false}
                            displayObjectSize={false}
                            name={null}
                            indentWidth={2}
                        />
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <h4>Response</h4>
                            <div style={{flex: '1 1 auto'}} />
                            { mapViewUrl && <a target="_geojson" style={{marginRight: 10}} href={mapViewUrl}>Show on Map</a> }
                        </div>  
                        <ReactJson 
                            src={response} 
                            theme="shapeshifter"
                            displayDataTypes={false}
                            displayObjectSize={false}
                            name={null}
                            indentWidth={2}
                        />
                    </div>
                </div>
            </ProductCard>
        </div>
    );
}

export default ApiProduct;