export const Request = {"mmsiNumbers":["123456789","987654321"]}
export const Response = [
    {
      "status": "ok", 
      "vessel": {
        "mmsi": "123456789", 
        "imo": "1234567", 
        "name": "SOME VESSEL NAME",
        "callsign": "ABCD1", 
        "flag": "Norway", 
        "type": "Other", 
        "max_draught": "6.2"
      }, 
      "current": {
        "navigational_status": "Restricted maneuverability", 
        "speed_over_ground": "6.4", 
        "course_over_ground": "78.7", 
        "true_heading": "78", 
        "cargo_type": "Hazard or Pollutant Category X", 
        "activity": "Towing", 
        "destination": "WEST OF SVALBARD", 
        "estimated_time_of_arrival": "2018-10-01T15:00:00.000Z"
      }, 
      "latlng": {
        "lat": 78.07095833333334, 
        "lng": 9.061568333333334
      }, 
      "signal_timestamp": "2018-10-02T04:16:43.000Z"
    },
    {
      "status": "ok", 
      "vessel": {
        "mmsi": "987654321", 
        "imo": "7654321", 
        "name": "OTHER VESSEL NAME",
        "callsign": "ABCDE", 
        "flag": "Norway", 
        "type": "Other", 
        "max_draught": "6.2"
      }, 
      "current": {
        "navigational_status": "Restricted maneuverability", 
        "speed_over_ground": "6.4", 
        "course_over_ground": "78.7", 
        "true_heading": "78", 
        "cargo_type": "Hazard or Pollutant Category X", 
        "activity": "Towing", 
        "destination": "WEST OF SVALBARD", 
        "estimated_time_of_arrival": "2018-10-01T15:00:00.000Z"
      }, 
      "latlng": {
        "lat": 78.07095833333334, 
        "lng": 9.061568333333334
      }, 
      "signal_timestamp": "2018-10-02T04:16:43.000Z"
    }
  ]