import React from 'react';
import {
    CodeContainer,
} from './styledComponents';

class JsonView extends React.Component{
    state = {
        Comp: null
    }

    async componentDidMount() {
        const { default: ReactJson } = await import('react-json-view');
        this.setState({ Comp: <ReactJson {...this.props} /> });
      }

    render(){
        const { Comp } = this.state;
        const { src } = this.props;

        return Comp
            ? (
                <CodeContainer>
                    { Comp }
                </CodeContainer>
            ) 
            : (
                <CodeContainer>
                    <pre>{ JSON.stringify(src, null, 2) }</pre>
                </CodeContainer>
            );
    }
}

export default JsonView;