import React from 'react';
import {
    StyledLink
} from './styledComponents';

const ProductIconButton = ({
    anchor,
    icon,
    name
}) => {
    return (
        <StyledLink to={anchor}>
            <section>
                { icon }
            </section>
            <h3>{ name }</h3>
        </StyledLink>
    )
}

export default ProductIconButton;